.carousel-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.carousel-item {
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-berzier(0.39, 0.575, 0.565, 1);
    background-color: #F2F6F8;
}

    .carousel-item.idcard {
        align-items: start;
        margin-bottom: 32px;
        overflow-y: scroll;
        scrollbar-width: none;
    }

    .carousel-item.hide {
        display: none;
    }
